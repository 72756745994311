import ng from 'angular';
import acpCore from 'core';

export class AcpToast {
  constructor(
    private $mdToast: ng.material.IToastService,
    private acpCoreDispatcher: any
  ) {
    'ngInject';
  }

  public show(data: AcpToastConfigData) {
    if (data.content) {
      let toastClass = data.toastClass || '';
      if (data.content === 'generic-error') {
        data.content = 'generic-error:core/errors';
        toastClass = 'failure';
      }
      data.template =
        '<md-toast class="' +
        toastClass +
        '"><span cms-html="' +
        data.content +
        '"></span></md-toast>';
    }

    if (!data.position) {
      data.position = 'top right';
    }
    this.$mdToast.show(data);
  }

  public hide() {
    this.$mdToast.hide();
  }

  public listen() {
    this.acpCoreDispatcher.toast.show.onValue((data) => this.show(data));
  }
}

export interface AcpToastConfigData {
  content: string;
  toastClass: string;
  template: string;
  position: string;
}

export default ng
  .module('acp.shared.services.acp-toast', [
    acpCore.name,
    'netspend.legos.core'
  ])
  .service('acpToast', AcpToast);
